@import "../../variables";

#alliance {
    align-items: center;
    background:
        linear-gradient(
            $cover-overlay-color,
            $cover-overlay-color
        ),
        url($cover-alliance);
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    .container {
        h1 {
            color: $secondary-text-color;
            @include fluid-type(font-size, 600px, 1200px, 36px, 50px);
            font-weight: bolder;
            margin-top: 50px;
        }

        h2 {
            color: $primary-text-color;
            font-family: Roboto;
            @include fluid-type(font-size, 600px, 1200px, 16px, 20px);
            font-weight: 50;
            margin: 50px 25vw;
        }

        .logos {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 50px;
            background-color: white;
            opacity: 35%;

            a {
                .company-logo {
                    margin: 25px;
                    max-height: 10vh;
                    max-width: 10vw;
                }

                &:hover {
                    .company-logo {
                        max-height: 12vh;
                        max-width: 12vw;
                    }
                }
            }
        }
    }
}
