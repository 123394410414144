@import "../../variables";

@mixin bg-images(
    $image-path: "https://images.unsplash.com/photo-1441716844725-09cedc13a4e7?q=80&fm=jpg&s=c895a1f219d174952415b9b7a0811e62",
    $overlay-color: rgba(255,255,255,0.8),
    $position: center center) {

    animation: bgFadeIn 1s ease-in both;
    position: relative;

    &:before {
        background:
            linear-gradient(
              $overlay-color,
              $overlay-color
            ),
            url($image-path);
        background-color: #CCCCCC;
        background-attachment: fixed;
        background-position: $position;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        bottom: 0;
        content: ' ';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }

    @keyframes bgFadeIn {
        0% { background-color: rgba(255, 255, 255, 1); }
        100% { background-color: rgba(255, 255, 255, 0); }
    }
}

#home {
    @include bg-images(
        $image-path: $cover-image,
        $overlay-color: $cover-overlay-color
    );

    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: center;
    text-align: center;

    .container {
        h1 {
            color: $secondary-text-color;
            @include fluid-type(font-size, 600px, 1200px, 36px, 90px);
            font-weight: 600;
            margin: 0;
        }

        h2 {
            color: $primary-text-color;
            @include fluid-type(font-size, 600px, 1200px, 36px, 90px);
            font-weight: 600;
            margin: 0;
        }
          
        h3 {
            color: $primary-text-color;
            font-family: Roboto Condensed;
            @include fluid-type(font-size, 600px, 1200px, 16px, 20px);
            font-weight: 100;
            margin: 50px 0 0 0;
        }

        @keyframes arrowGoingDown {
            from {bottom: 100px;}
            to {bottom: 50px;}
        }
        .arrow {
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: arrowGoingDown;
            bottom: 100px;
            left: 0;
            margin: 0 auto;
            position: absolute;
            right: 0;
        }
    }
}
