@import "./variables";

.App {
  text-align: center;

  .language {
    background-color: $primary-color-menu;
    border-bottom-left-radius: 5px;
    border-color: $secondary-color;
    border-style: solid;
    border-top-left-radius: 5px;
    border-width: 1px 0 1px 1px;
    margin: 0;
    padding-left: 10px;
    position: fixed;
    right: 0;
    top: 25vh;
    z-index: 1000;

    span {
      color: $secondary-text-color;
      @include fluid-type(font-size, 600px, 1200px, 12px, 15px);

      .languageText {
        color: $tertiary-text-color;
        font-family: Roboto Condensed;
        font-weight: bold;
      }
    }
  }

  .space-before:before {
    content: '';
    display: block;
    height: 2em;
  }
}
