@import '../../variables';

#prefooter {
    align-items: center;
    background-color: $secondary-color;
    color: $primary-text-color;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    #logo {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 7vw;
        justify-content: center;
        margin: 0 1.5em;

        .footer-company-logo {
            height: 2vw;
        }
    }

    #social-networks {
        display: flex;
        flex-direction: row;

        .logo {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 7vw;
            justify-content: center;
            width: 7vw;

            &.calendly {
                background-color: #4f4f4f;
            }

            &.facebook {
                background-color: #474747;
            }

            &.instagram {
                background-color: #333333;
            }

            &.linkedin {
                background-color: #191919;
            }

            &.twitter {
                background-color: #161616;
            }

            &.whatsapp {
                background-color: #0e0e0e;
            }

            .url {
                color: $secondary-text-color;
                font-size: 2vw;
                text-decoration: none;
            }
        }
    }
}
