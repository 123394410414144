@import "../../variables";

footer {
    align-items: center;
    justify-content: space-between;
    background-color: $primary-color;
    color: $primary-text-color;
    display: flex;
    height: 3em;
    width: 100%;

    small {
        font-family: Roboto Condensed;
        @include fluid-type(font-size, 600px, 1200px, 10px, 12px);
        margin-left: 2em;
        margin-right: 2em;

        a {
            color: $secondary-text-color;
            text-decoration: none;
        }
    }
}
