$primary-color: #222222;
$secondary-color: #f7e000;
$tertiary-color: #ffffff;
$primary-color-menu: white;
$primary-text-color: white;
$secondary-text-color: #f7e000;
$tertiary-text-color: #222222;
$special-objects: #666666ff;
$cover-image: "../../assets/imgs/cover/cover.png";
$cover-contactus: "../../assets/imgs/contactUs/coverContactUs.png";
$cover-alliance: "../../assets/imgs/strategicAlliance/coverAlliance.png";
$cover-overlay-color: #000000cc;
$services-overlay-color: #f7e00066;

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}
    
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}
