@import "../../variables";

$primaryColor: $primary-color-menu;
$secondaryColor: $primary-color-menu;
$tertiaryColor: $primary-color;
$overLinkColor: $secondary-text-color;

$flex_nav_hoverColor: $secondaryColor;
$flex_nav_borderColor: $secondaryColor;
$flex_nav_linkColor: $primary-text-color;
$flex_nav_hotdogsColor: $primary-text-color;

$flex_nav_subNavWidth: 12.5em;
$flex_nav_breakpoint: 60em;

@-webkit-keyframes bugfix { from {padding:0;} to {padding:0;} }
@keyframes bugfix { from {padding:0;} to {padding:0;} }

header {
	align-items: center;
	background-color: transparent;
	display: flex;
    flex-direction: row;
	justify-content: space-between;
	position: fixed;
	top: 0;
	transition: 1.5s all;
	width: 100vw;
	z-index: 2000;

	&.nav-transition {
		background-color: $primaryColor;
	}
	
	@media (max-width: $flex_nav_breakpoint) {
		align-items: flex-start;
		flex-wrap: wrap;
		flex-direction: column;
	}

	.logo {
		margin: 5vh 5vw;

		&.nav-transition-logo {
			margin: 2vh 5vw;
		}

		@media (max-width: $flex_nav_breakpoint) {
			margin: 3vh 5vw;
		}

		.company-logo {
			height: 100%;
		}
	}

	#nav {
		align-items: flex-end;
		display: flex;
        flex-direction: column;
        justify-content: center;
		margin: 5vh 5vw;

		&.nav-transition-nav {
			margin: 2vh 5vw;
		}

		@media (max-width: $flex_nav_breakpoint) {
			background: $primaryColor;
			margin: 0;
			position: fixed;
			right: 0;

			&.nav-transition-nav {
				margin: 2vh 0vw;
			}
		}

		ul {
			display: none;
			list-style: none;
			margin: 0px;
			padding: 0px;
			width: 100%;

			@media (max-width: $flex_nav_breakpoint) {
				background: $primaryColor;
				height: 100vh;
				margin: 50px 0;
				width: auto;
			}

			li {
				a {
					background: transparent;
					color: $flex_nav_linkColor;
					display: block;
					padding: 0 2em;
					text-decoration: none;

					&.nav-transition-a {
						color: $primary-color;
					}

					.squareBracket {
						color: $overLinkColor;
					}

					&:hover {
						color: $overLinkColor;
					}

					&:active {
						color: darken($overLinkColor, 10%);
					}

					@media (max-width: $flex_nav_breakpoint) {
						background: $primaryColor;
						color: $primary-color;
						margin: 10px 0;
					}
				}
			}

			.svg-line {
				display: none;
			}
		}

		input.trigger {
			left: -9999px;
			position: absolute;
			top: -9999px;
			&:checked ~ ul {
				display: block !important;
				@media (min-width: $flex_nav_breakpoint) {
					display: flex;
					flex-direction: column;
				}
			}
			&:checked ~ label:after {
				color: $primary-color;
			}
		}

		label {
			background: $primaryColor;
			color: $primaryColor;
			cursor: pointer;
			display: flex;
			font-size: 1.1em;
			line-height: 2em;
			margin: 0;
			min-height: 2em;
			&.nav-transition-label:after {
				color: $primary-color;
				margin: 2vh 5vw;
			}
			&:hover {
				color: $overLinkColor;
			}
			&:after {
				content: "\2261";
				color: $flex_nav_hotdogsColor;
				font-size: 1.8em;
				margin: 3vh 5vw;
				position: fixed;
				right: 0;
				top: 0;
				&:hover {
					color: $overLinkColor;
				}
			}
		}

		@media(min-width:$flex_nav_breakpoint){
			ul {
				display: flex;
				flex-direction: row;

				li {
					flex: 1;
					position: relative;
					text-align: center;

					&:hover > ul {
						display: block !important;
					}

					.svg-line {
						display: block !important;

						.line {
							stroke: $special-objects;
							stroke-width: 2;
						}
					}
				}
			}

			label {
				display: none;
			}
		}
	}
}
