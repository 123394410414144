@import '../../variables';
@import 'react-alice-carousel/lib/scss/alice-carousel.scss';

#projects {
    background-color: $tertiary-color;
    position: relative;
    text-align: center;
    width: 100%;

    @keyframes fade-in {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    .modal-overlay {
        background: rgba(0,0,0,.4);
        bottom: 0;
        display: none;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 15000;
    }

    /* display the modal overlay when the input[type=radio] is checked */
    .modal-state:checked + .modal-overlay {
        animation: fade-in .4s;
        display: block;
        opacity: 1;
    }

    @keyframes scoot-up {
      0%   { margin-top: 80px; }
      100%  { margin-top: 50px; }
    }

    .modal {
        background: lighten($tertiary-color, 10%);
        box-shadow: 0px 0px 38px rgba(0,0,0,.2);
        position: relative;
        text-align: left;
    }

    .modal-state:checked + .modal-overlay .modal {
        animation: scoot-up .2s;
        animation-timing-function: ease-out;
        height: 90vh;
        margin: 5vh auto;
        max-width: 850px;
        overflow-y: auto;
        transform-origin: 50% 0;
        width: 90vw;
    }

    .modal-close {
        position: absolute;
        right: 20px;
        top: 40px;
    }

    .modal-overlay-close {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .modal-state {
        display: none;
    }

    .button:hover,
    .button:focus {
        cursor: pointer;
    }

    /* close button */
    .button-close {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $secondary-color;
        height: 30px;
        padding: 10px;
        right: 0;
        text-align: center;
        top: 0;
        width: 30px;
        z-index: 200;

        span {
            color: $tertiary-text-color;
            font-family: Roboto Mono;
            font-size: 20px;
            font-weight: bolder;
        }
    }

    .button--inline {
        padding: .25em .5em;
    }

    .button-close:hover,
    .button-close:focus {
        background-color: $primary-color;

        span {
            color: $primary-text-color;
        }
    }

    .project-info {
        display: 'flex';
        flex-direction: column;

        .project-info-header {
            position: absolute;
            width: 100%;

            .project-info-image-container {
                align-items: center;
                display: flex;
                justify-content: center;
                overflow: hidden;

                .project-info-image {
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            .project-info-basic {
                align-items: center;
                background-image: linear-gradient(transparent, lighten($primary-color, 40%), lighten($primary-color, 20%), $primary-color);
                bottom: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px 0;
                position: absolute;
                width: 100%;

                .project-info-title-description {
                    @include fluid-type(margin-left, 600px, 1200px, 20px, 40px);

                    .project-info-title {
                        color: $primary-text-color;
                        @include fluid-type(font-size, 600px, 1200px, 16px, 32px);
                        margin: 0;
                    }

                    .project-info-description {
                        margin: 0;
                        font-weight: 100;

                        .project-info-type {
                            color: $secondary-text-color;
                            @include fluid-type(font-size, 600px, 1200px, 10px, 14px);
                            text-transform: uppercase;
                        }

                        .project-info-year {
                            color: $primary-text-color;
                            @include fluid-type(font-size, 600px, 1200px, 10px, 14px);
                        }
                    }
                }

                .project-info-tags {
                    display: flex;
                    flex-wrap: wrap;
                    @include fluid-type(font-size, 600px, 1200px, 10px, 14px);
                    font-weight: 100;
                    margin: 0;
                    @include fluid-type(margin-left, 600px, 1200px, 20px, 40px);
                    @include fluid-type(margin-right, 600px, 1200px, 20px, 40px);

                    .tag {
                        .square-brackets {
                            color: $secondary-text-color;
                        }

                        .tag-text {
                            color: $primary-text-color;
                        }
                    }
                }
            }
        }

        .project-info-text {
            color: $tertiary-text-color;
            column-count: 2;
            @include fluid-type(column-gap, 600px, 1200px, 20px, 40px);
            @include fluid-type(font-size, 600px, 1200px, 10px, 14px);
            @include fluid-type(padding, 600px, 1200px, 20px, 40px);
            position: relative;
            top: 50vh;
        }
    }

    .background-line {
        background-color: $secondary-color;
        height: 150px;
        position: absolute;
        top: 50%;
        width: 100%;
        z-index: 0;
    }

    .with-line {
        color: $tertiary-text-color;
        @include fluid-type(font-size, 600px, 1200px, 28px, 60px);
        font-weight: bolder;
        margin: 30px 0;
        position: relative;
        z-index: 1;

        &:before {
            border-top-color: $secondary-color;
            border-top-style: solid;
            @include fluid-type(border-top-width, 600px, 1200px, 14px, 30px);
            bottom: 0;
            content: "";
            left: 0;
            margin: 0 auto; /* this centers the line to the full width specified */
            position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
            right: 0;
            @include fluid-type(top, 600px, 1200px, 14px, 30px);
            @include fluid-type(width, 600px, 1200px, 160px, 400px);
            z-index: -1;
        }
    }

    h2 {
        font-family: Roboto Condensed;
        @include fluid-type(font-size, 600px, 1200px, 12px, 16px);
        font-weight: 300;
        margin: 50px 20%;
    }

    .alice-carousel {
        z-index: 10;
    }

    .carousel {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .project-card {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 10px;

        .card-image {
            float: left;
            height: 200px;
            overflow: hidden;
            position: relative;
            width: 350px;

            &:before {
                background: rgba(0,0,0,.1);
                bottom: 0;
                content: "";
                display: block;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }

            img {
                min-height: 100%;
                min-width: 100%;
                max-height: 100%;
                max-width: 100%;
            }
        }

        .card-text {
            background-color: $primary-color;
            text-align: left;
            width: 350px;

            h1 {
                color: $primary-text-color;
                @include fluid-type(font-size, 600px, 1200px, 12px, 18px);
                margin-bottom: 0;
                margin-left: 10px;
                margin-top: 20px;
            }

            h2 {
                @include fluid-type(font-size, 600px, 1200px, 12px, 10px);
                font-weight: 100;
                margin-bottom: 20px;
                margin-left: 10px;
                margin-top: 0;

                .first {
                    color: $secondary-text-color;
                    text-transform: uppercase;
                }

                .second {
                    color: $primary-text-color;
                }
            }
        }
    }
}
