@import '../../variables';

#services {
    display: flex;
    flex-direction: row;

    @media (max-width: 60em) {
        flex-wrap: wrap;
    }

    .service-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 33vw;

        @media (max-width: 60em) {
            width: 100%;
        }

        .upper-section {
            background-color: $secondary-color;
            width: 100%;

            .image-service1 {
                background: url(../../assets/imgs/services/services1.jpg);
                background-position: center;
                background-size: cover;
                height: 50vh;
            }

            .image-service2 {
                background: url(../../assets/imgs/services/services2.jpg);
                background-position: center;
                background-size: cover;
                height: 50vh;
            }

            .image-service3 {
                background: url(../../assets/imgs/services/services3.jpg);
                background-position: center;
                background-size: cover;
                height: 50vh;
            }

            .title {
                color: $tertiary-text-color;
                display: flex;
                flex-direction: column;
                font-family: Roboto Mono;
                @include fluid-type(font-size, 600px, 1200px, 18px, 26px);
                font-weight: bold;
                height: 10vh;
                justify-content: center;
                margin-left: 3vw;
                margin-right: 3vw;
            }
        }

        .description {
            color: $tertiary-text-color;
            display: flex;
            flex-direction: column;
            font-family: Roboto Condensed;
            @include fluid-type(font-size, 600px, 1200px, 14px, 18px);
            font-weight: 100;
            height: 20vh;
            justify-content: center;
            margin-left: 3vw;
            margin-right: 3vw;
        }

        button {
            width: 60%;
            background-color: $secondary-color;
            border-radius: 12px;
            color: #000;
            cursor: pointer;
            font-weight: bold;
            font-family: Roboto Mono;
            padding: 10px 15px;
            text-align: center;
            transition: 200ms;
            box-sizing: border-box;
            border: 0;
            font-size: 16px;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            margin-bottom: 100px;

            &:not(:disabled):hover,
            &:not(:disabled):focus {
                outline: 0;
                background: #f4e603;
                box-shadow: 0 0 0 2px rgba(0,0,0,.2), 0 3px 8px 0 rgba(0,0,0,.15);
            }

            &:disabled {
                filter: saturate(0.2) opacity(0.5);
                -webkit-filter: saturate(0.2) opacity(0.5);
                cursor: not-allowed;
            }
        }
    }
}
