@import "../../variables";

#aboutus {
    align-items: center;
    background-color: $tertiary-color;
    background-image: url('../../assets/imgs/aboutUs/coverAboutUs.png');
    background-position-x: 50vw;
    background-position-y: 50%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    height: 80vh;
    justify-content: center;
    text-align: center;

    .container {
        h1 {
            color: $tertiary-text-color;
            @include fluid-type(font-size, 600px, 1200px, 36px, 80px);
            font-weight: bolder;
            margin: 0;
            position: relative;
            z-index: 1;
            
            &:before {
                border-top-color: $secondary-color;
                border-top-style: solid;
                @include fluid-type(border-top-width, 600px, 1200px, 18px, 40px);
                content:"";
                left: 0; right: 0; bottom: 0;
                margin: 0 auto; /* this centers the line to the full width specified */
                position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
                @include fluid-type(top, 600px, 1200px, 18px, 40px);
                @include fluid-type(width, 600px, 1200px, 120px, 300px);
                z-index: -1;
            }
        }

        h2 {
            color: $tertiary-text-color;
            font-family: Roboto;
            @include fluid-type(font-size, 600px, 1200px, 16px, 22px);
            font-weight: 50;
            margin: 30px 20vw;
        }
    }
}
