@import '../../variables';

#contactus {
    align-items: center;
    background: white;
    background-size: cover;
    display: flex;
    flex-direction: row;
    height: 80vh;
    justify-content: center;
    text-align: center;

    .container {
        .title {
            color: $secondary-text-color;
            @include fluid-type(font-size, 600px, 1200px, 36px, 50px);
            font-weight: bolder;
            margin: 0;
        }

        .subtitle {
            color: black;
            font-family: Roboto;
            @include fluid-type(font-size, 600px, 1200px, 16px, 18px);
            font-weight: 100;
            margin: 50px 25vw;
        }

        .info {
            color: black;
            font-family: Roboto Mono;
            @include fluid-type(font-size, 600px, 1200px, 16px, 18px);
            font-weight: 500;

            .email {
                color: $secondary-text-color;
            }
        }

        button {
            width: 20%;
            background-color: $secondary-color;
            border-radius: 12px;
            color: #000;
            cursor: pointer;
            font-weight: bold;
            font-family: Roboto Mono;
            padding: 10px 15px;
            text-align: center;
            transition: 200ms;
            box-sizing: border-box;
            border: 0;
            font-size: 16px;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            margin-bottom: 100px;

            &:not(:disabled):hover,
            &:not(:disabled):focus {
                outline: 0;
                background: #f4e603;
                box-shadow: 0 0 0 2px rgba(0,0,0,.2), 0 3px 8px 0 rgba(0,0,0,.15);
            }

            &:disabled {
                filter: saturate(0.2) opacity(0.5);
                -webkit-filter: saturate(0.2) opacity(0.5);
                cursor: not-allowed;
            }
        }
    }
}
