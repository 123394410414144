@import "../../variables";

#technologies {
    align-items: flex-start;
    background-color: $primary-color;
    color: $special-objects;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5vw;
    text-align: left;

    h1 {
        color: $secondary-text-color;
        @include fluid-type(font-size, 600px, 1200px, 14px, 18px);
        font-weight: 300;
    }

    .tech {
        span {
            display: flex;
            flex-wrap: wrap;

            i {
                @include fluid-type(font-size, 600px, 1200px, 32px, 50px);
                margin: 20px;
            }
        }
    }

    .proc, .devops {
        width: 100%;
        ul {
            display:grid;
            grid-gap: 1em;
            grid-template-columns: repeat(3, 1fr);
            list-style: none;
            padding: 0;

            li {
                display: flex;
                @include fluid-type(font-size, 600px, 1200px, 10px, 14px);
                padding: 0;
            }
        }
    }
}
