@import '../../variables';

#testimonials {
    align-items: center;
    background:
        linear-gradient(
            $cover-overlay-color,
            $cover-overlay-color
        ),
        url($cover-contactus);
    background-size: cover;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: center;
    text-align: center;

    .container {
        .title {
            color: $secondary-text-color;
            @include fluid-type(font-size, 600px, 1200px, 36px, 50px);
            font-weight: bolder;
            margin: 0;
        }

        .text {
            color: $primary-text-color;
            font-family: Roboto;
            @include fluid-type(font-size, 600px, 1200px, 16px, 18px);
            font-weight: 100;
            margin: 50px 25vw 20px;
            font-style: italic;
        }

        .author {
            color: $primary-text-color;
            font-family: Roboto Mono;
            @include fluid-type(font-size, 600px, 1200px, 16px, 18px);
            font-weight: 500;
            margin-bottom: 70px;

            a {
                text-decoration: none;
                color: $primary-text-color;

                &:hover {
                    color: $secondary-text-color;
                }
            }
        }
    }
}
